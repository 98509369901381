import {getAdminGroupId, getBekkApiScopes, getScopes} from 'src/config';
import {getMsalInstance} from "./msal";
import {IEmployee} from "./types/Employee";


export function infoFromToken() : IEmployee {
    const msalInstance = getMsalInstance();
    const activeUser = msalInstance.getActiveAccount()
    if(!activeUser) throw new Error("Could not find active user!")
    const claims = activeUser.idTokenClaims;
    if(!claims) throw new Error("Could not find claims from id token")
    if(!(claims.name && claims.preferred_username && claims.employeeId) ) throw new Error("Claims does not contain name, email or employee id")

    return {
        name: claims.name,
        email: claims.preferred_username,
        id: Number(claims.employeeId),
    };
}

const getGroupIds = async () => {
    const accessToken = await getAccessTokenForEntraId()
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return await fetch('https://graph.microsoft.com/v1.0/me/memberOf', options)
        .then(response => response.json().then(x => x.value.map((y: any) => y.id)))
}


export const isMemberOfSpiritfond = async () => {
    const groupIds = await getGroupIds()
    return groupIds.includes(getAdminGroupId())
};

export const getIdToken = (): any => {
    const msalInstance = getMsalInstance();
    const activeAccount = msalInstance.getActiveAccount();

    if(activeAccount === null) throw new Error('No id token claims found!');
    return activeAccount.idToken
}

// Hente ut ansatt-id fra id token
export function getEmployeeId(): number {
    const msalInstance = getMsalInstance();
    const activeAccount = msalInstance.getActiveAccount();
    if(activeAccount === null) throw new Error('No id token claims found!');
    const idClaims = activeAccount.idTokenClaims;
    if(idClaims === undefined) throw new Error('No id token claims found!');
    const employeeId = idClaims['employeeId'] as string;

    if (!employeeId) {
        throw new Error('No employee ID found!');
    }

    return parseInt(employeeId);
}

const getAccessTokenForEntraId = async () => getAccessToken(getScopes())
export const getAccessTokenForBekkApi = async () => getAccessToken(getBekkApiScopes())

// Hente access token for prating med Bekk-apier
const getAccessToken = async (scopes: Array<string>) => {
    const msalInstance = getMsalInstance();
    try {
        const authRequest = { scopes: scopes };
        const tokenResponse = await msalInstance.acquireTokenSilent(authRequest);
        return tokenResponse.accessToken;
    } catch {
        return undefined;
    }
};
import {BrowserCacheLocation, PublicClientApplication} from "@azure/msal-browser";
import {getAuthority, getClientId, getMsalConfig, setMsalConfig} from "./config";

let msalInstance: PublicClientApplication

export const initializeMsalInstance = async () => {
    const config = await getMsalConfig()
    setMsalConfig(config)

    msalInstance = new PublicClientApplication({
        auth: {
            clientId: getClientId(),
            authority: getAuthority(),
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage
        }
    });


    await msalInstance.initialize()
}

export const getMsalInstance = () => msalInstance;
import { useEffect, useState } from 'react';
import {getIdToken} from 'src/auth';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useFetchApi<T>(
    apiUrl: string,
    initialState: T,
    headers?: any,
): T {
    const [data, setData] = useState(initialState);

    useEffect(() => {
        fetch(apiUrl, {
            headers: {
                authorization: `Bearer ${getIdToken()}`,
                ...headers,
            },
        })
            .then(response => {
                response.json().then(x => {
                    setData(x)
                })
            }).catch(err => console.log(err));
    }, [apiUrl, headers]);

    return data;
}

import * as serviceWorker from 'src/serviceWorker';
import {
    getConfig,
    getScopes,
    setConfig,
} from 'src/config';
import {
    AuthenticationResult,
    EventType,
    InteractionType,
} from "@azure/msal-browser";
import ReactDOM from "react-dom";
import {
    AuthenticatedTemplate,
    MsalAuthenticationTemplate,
    MsalProvider,
    UnauthenticatedTemplate,
} from "@azure/msal-react";
import React from "react";
import {App} from "./components/App/App";
import {initializeFirebase} from "./firebase";
import {getMsalInstance, initializeMsalInstance} from "./msal";


const init = async () => {
    await initializeMsalInstance()
    const msalInstance = getMsalInstance()

    const config = await getConfig();
    setConfig(config);

    if (
        !msalInstance.getActiveAccount() &&
        msalInstance.getAllAccounts().length > 0
    ) {
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
    }

    msalInstance.addEventCallback((event) => {
        const payload = event.payload as AuthenticationResult;
        if(!payload) return;

        const account = payload.account;
        if (event.eventType === EventType.LOGIN_SUCCESS && account) {
            msalInstance.setActiveAccount(account);
        } else if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && account){
            msalInstance.setActiveAccount(account)
        }
    });

    msalInstance.handleRedirectPromise().then(() => {
        if(msalInstance.getActiveAccount()) {
            msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
        } else {
            msalInstance.loginRedirect()
        }
    }).catch(error => console.log("error", error))


    const authRequest = {
        scopes: getScopes(),
    }

    const rootNode = document.getElementById("root");

    ReactDOM.render(
        <MsalProvider instance={msalInstance}>
            {}
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={authRequest}
            >
                <App />
            </MsalAuthenticationTemplate>
        </MsalProvider>,
        rootNode
    );

    await initializeFirebase()
}

init();

serviceWorker.unregister();

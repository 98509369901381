let scopes: string;
let bekkApiScopes: string;
let employeeSvcUrl: string;
let firebaseApiKey: string;
let firebaseAuthDomain: string;
let firebaseDatabaseURL: string;
let firebaseProjectId: string;
let firebaseAppId: string;
let environment: string;
let entraIdClientId: string;
let entraIdTenantId: string;
let entraIdAdminGroupId: string;

interface IConfig {
    scopes: string;
    bekkApiScopes: string;
    employeeSvcUrl: string;
    firebaseApiKey: string;
    firebaseAuthDomain: string;
    firebaseDatabaseURL: string;
    firebaseProjectId: string;
    firebaseAppId: string;
    environment: string;
    entraIdClientId: string;
    entraIdTenantId: string;
    entraIdAdminGroupId: string;
}


interface IMsalConfig {
    entraIdClientId: string;
    entraIdTenantId: string;
}

interface IFirebaseConfig {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    appId: string;
}

export function setConfig(config: IConfig): void {
    scopes = config.scopes;
    bekkApiScopes = config.bekkApiScopes;
    employeeSvcUrl = config.employeeSvcUrl;
    firebaseApiKey = config.firebaseApiKey;
    firebaseAuthDomain = config.firebaseAuthDomain;
    firebaseDatabaseURL = config.firebaseDatabaseURL;
    firebaseAppId = config.firebaseAppId;
    firebaseProjectId = config.firebaseProjectId;
    environment = config.environment;
    entraIdClientId = config.entraIdClientId;
    entraIdTenantId = config.entraIdTenantId;
    entraIdAdminGroupId = config.entraIdAdminGroupId;
}

export async function getConfig(): Promise<IConfig> {
    const response = await fetch('/config');
    return response.json() as Promise<IConfig>;
}


export function getScopes(): Array<string> {
    return scopes.split(" ");
}

export function getBekkApiScopes(): Array<string> {
    return bekkApiScopes.split(" ")
}

export function getEmployeeSvcUrl(): string {
    return employeeSvcUrl;
}

export function getFirestoreApplicationsPath(): string {
    return `applications-${environment}`;
}

export function getFirestoreQuartersPath(): string {
    return `quarters-${environment}`;
}

export function getFirebaseConfig(): IFirebaseConfig {
    return {
        apiKey: firebaseApiKey,
        authDomain: firebaseAuthDomain,
        databaseURL: firebaseDatabaseURL,
        projectId: firebaseProjectId,
        appId: firebaseAppId,
    };
}

export async function getMsalConfig(): Promise<IMsalConfig> {
    const response = await fetch('/msal-config');
    return response.json() as Promise<IMsalConfig>;
}

export function setMsalConfig(config: IMsalConfig): void {
    entraIdClientId = config.entraIdClientId;
    entraIdTenantId = config.entraIdTenantId;
}

export function getClientId(): string {
    return entraIdClientId
}

export function getAuthority(): string {
    return `https://login.microsoftonline.com/${entraIdTenantId}`
}

export function getAdminGroupId(): string {
    return entraIdAdminGroupId
}
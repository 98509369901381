import { getFirebaseConfig } from 'src/config';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import {getIdToken} from './auth';

let db: firebase.firestore.Firestore;

export const initializeFirebase = async () => {
    if(firebase.apps.length > 0) return
    const token = await fetch('/firebase', {
        headers: { Authorization: `Bearer ${getIdToken()}` },
    }).then(res => res.json());
    const firebaseApp = await firebase.initializeApp(getFirebaseConfig());
    await firebaseApp.auth().signInWithCustomToken(token.firebaseToken);
    db = firebaseApp.firestore();
};

export const getFirestore = () => db;
